import React, { useState, useEffect } from "react";
import axios from "axios";

const Gateway = () => {
  const [RazorPayout, setRazorpayout] = useState(true);
  const [RazorDeposit, setRazorDeposit] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState("");
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [decentroPayout, setdecentropayout] = useState(true);
  const [decentroDeposit, setdecentroDeposit] = useState(true);
  const [decentroAuto, setdecentroAuto] = useState(false);
  const [settingId, setSettingId] = useState("");

  const [isCashFreeActive, setIsCashFreeActive] = useState(false);
  const [isPhonePeActive, setIsPhonePeActive] = useState(false);
  const [isRazorPayActive, setIsRazorPayActive] = useState(false);
  const [isDecentroActive, setIsDecentroActive] = useState(false);
  const [isManualPaymentActive, setIsManualPaymentActive] = useState(false);
  const [isManualUPIQR, setIsManualUPIQR] = useState("");
  const [isManualUPIid, setIsManualUPIid] = useState("");
  const [isUpiGatewayActive, setIsUpiGatewayActive] = useState(false);
  const [isUpiGatewayActive2, setIsUpiGatewayActive2] = useState(false);
  const [isGtelGatewayActive, setIsGtelGatewayActive] = useState(false);
  const [isMypayActive, setIsMypayActive] = useState(false);
  const [isManualPayoutActive, setIsManualPayoutActive] = useState(false);
  const [isManualBankPayoutActive, setIsManualBankPayoutActive] =
    useState(false);
  const [isCashFreePayoutActive, setIsCashFreePayoutActive] = useState(false);
  const [isRazorPayPayoutActive, setIsRazorPayPayoutActive] = useState(false);
  const [isDecentroPayoutActive, setIsDecentroPayoutActive] = useState(false);
  const [maxAutopayAmt, setMaxAutopayAmt] = useState(0);
  const [isMypayPayoutActive, setIsMypayPayoutActive] = useState(false);
  const [isMypayPayoutBankActive, setIsMypayPayoutBankActive] = useState(false);
  const [isDecentroPayoutAuto, setIsDecentroPayoutAuto] = useState(false);
  const [isMypayPayoutAuto, setIsMypayPayoutAuto] = useState(false);
  const [isRazorPayPayoutAuto, setIsRazorPayPayoutAuto] = useState(false);
  const [isUpiPayActive, setIsUpiPayActive] = useState(false);
  const [isUpiImbActive, setIsUpiImbActive] = useState(false);

  const [isUpiGActive, setIsUpiGActive] = useState(false);

  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  useEffect(() => {
              const access_token = localStorage.getItem("token")

        const headers = {
            Authorization: `Bearer ${access_token}`
        }

    axios.get(baseUrl + "gatewaysettings/data", {headers}).then((res) => {
      console.log(res.data);
      setSettingId(res.data._id ? res.data._id : "");
      setRazorpayout(res.data.RazorPayout);
      setRazorDeposit(res.data.RazorDeposit);
      setRazorpayAuto(res.data.RazorpayAuto);
      setdecentropayout(res.data.decentroPayout);
      setdecentroDeposit(res.data.decentroDeposit);
      setdecentroAuto(res.data.decentroAuto);
      setRazorpayKey(res.data.RazorPayKey);
      setRazorpaysecretKey(res.data.RazorPaySecretKey);
      setAccountName(res.data.AccountName);

      setIsCashFreeActive(res.data.isCashFreeActive);
      setIsPhonePeActive(res.data.isPhonePeActive);
      setIsRazorPayActive(res.data.isRazorPayActive);
      setIsDecentroActive(res.data.isDecentroActive);
      setIsManualPaymentActive(res.data.isManualPaymentActive);
      setIsManualUPIQR(res.data.isManualUPIQR);
      setIsManualUPIid(res.data.isManualUPIid);
      setIsUpiGatewayActive(res.data.isUpiGatewayActive);
      setIsUpiGatewayActive2(res.data.isUpiGatewayActive2);
      setIsGtelGatewayActive(res.data.isGtelGatewayActive);
      setIsMypayActive(res.data.isMypayActive);
      setIsManualPayoutActive(res.data.isManualPayoutActive);
      setIsManualBankPayoutActive(res.data.isManualBankPayoutActive);
      setIsCashFreePayoutActive(res.data.isCashFreePayoutActive);
      setIsRazorPayPayoutActive(res.data.isRazorPayPayoutActive);
      setIsDecentroPayoutActive(res.data.isDecentroPayoutActive);
      setMaxAutopayAmt(res.data.maxAutopayAmt);
      setIsMypayPayoutActive(res.data.isMypayPayoutActive);
      setIsMypayPayoutBankActive(res.data.isMypayPayoutBankActive);
      setIsDecentroPayoutAuto(res.data.isDecentroPayoutAuto);
      setIsRazorPayPayoutAuto(res.data.isRazorPayPayoutAuto);
      setIsMypayPayoutAuto(res.data.isMypayPayoutAuto);
      setIsUpiPayActive(res.data.isUpiPayActive);
      setIsUpiImbActive(res.data.isUpiImbActive);
      setIsUpiGActive(res.data.isUpiGActive);
    });
  }, [baseUrl]);

const handleSubmit = async (e) => {
  e.preventDefault();
  const access_token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${access_token}`
  };

  try {
    const response = await axios.post(
      baseUrl + `gatewaysettings`,
      {
        settingId,
        RazorPayout,
        RazorDeposit,
        RazorpayAuto,
        decentroPayout,
        decentroDeposit,
        decentroAuto,
        RazorPayKey,
        RazorPaySecretKey,
        AccountName,
        isCashFreeActive,
        isPhonePeActive,
        isRazorPayActive,
        isDecentroActive,
        isManualPaymentActive,
        isManualUPIQR,
        isManualUPIid,
        isUpiGatewayActive,
        isUpiGatewayActive2,
        isGtelGatewayActive,
        isMypayActive,
        isManualPayoutActive,
        isManualBankPayoutActive,
        isCashFreePayoutActive,
        isRazorPayPayoutActive,
        isDecentroPayoutActive,
        maxAutopayAmt,
        isMypayPayoutActive,
        isMypayPayoutBankActive,
        isDecentroPayoutAuto,
        isRazorPayPayoutAuto,
        isMypayPayoutAuto,
        isUpiPayActive,
        isUpiImbActive,
        isUpiGActive
      },
      { headers }  // Pass the headers as the third parameter
    );
    console.log(response.data.status);
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  } catch (error) {
    console.error("Error submitting settings:", error);
    alert("An error occurred while submitting settings");
  }
};

  return (
    <>
      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit}
        method="patch"
        encType="multipart/form-data"
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              Bank Withdraw
            </label>
            <select
              className="form-control"
              value={isDecentroPayoutActive}
              onChange={(e) =>
                setIsDecentroPayoutActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              UPI Withdraw
            </label>
            <select
              className="form-control"
              value={isRazorPayPayoutActive}
              onChange={(e) =>
                setIsRazorPayPayoutActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              UPI Gateway 1
            </label>
            <select
              className="form-control"
              value={isUpiGatewayActive}
              onChange={(e) =>
                setIsUpiGatewayActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              UPI Gateway 2
            </label>
            <select
              className="form-control"
              value={isUpiGatewayActive2}
              onChange={(e) =>
                setIsUpiGatewayActive2(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>
        
        <div className="form-row" style={{display:"none"}}>
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              GTEl Gateway 1
            </label>
            <select
              className="form-control"
              value={isGtelGatewayActive}
              onChange={(e) =>
                setIsGtelGatewayActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              UPI WSK
            </label>
            <select
              className="form-control"
              value={isUpiPayActive}
              onChange={(e) =>
                setIsUpiPayActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              QR service
            </label>
            <select
              className="form-control"
              value={isUpiGActive}
              onChange={(e) =>
                setIsUpiGActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>
        <div className="form-row"style={{display:"none"}}>
          <div className="form-group col-md-4">
            <label htmlFor="decentroPayout" className="col-2 my-1">
              UPI WSK
            </label>
            <select
              className="form-control"
              value={isUpiImbActive}
              onChange={(e) =>
                setIsUpiImbActive(e.target.value === "true")
              }
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-danger">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;
